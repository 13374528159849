import React from 'react';

const HomePage = () => {
  //To do change this
  window.location.replace('/Resource');

  return (
    <></>
  );
};

export default HomePage;
