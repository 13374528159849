import React from 'react';
import { TextField } from 'react-admin';
import { Grid } from '@mui/material';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import { ChipList } from '@semapps/list-components';
import { MapField } from '@semapps/geo-components';
import Title from "../../../../layout/Title";
import { Hero, MainList, SideList } from '../../../../common/list';
import Show from "../../../../layout/show/Show";

// const ConditionalSourceDefinedHandler = ({ record, source, children, ...otherProps }) => {
//   if (record?.[source] && (!Array.isArray(record[source]) || record[source].length > 0)) {
//     return React.Children.map(children, (child, i) => {
//       return React.cloneElement(child, { ...otherProps, record, source });
//     });
//   } else {
//     return <></>;
//   }
// };

const PersonShow = props => (
  <Show title={<Title />} {...props}>
    <Grid container spacing={5}>
      <Grid item xs={12} sm={9}>
        <Hero image="image">
          <TextField source="pair:firstName" />
          <TextField source="pair:lastName" />
          <TextField source="pair:comment" />
        </Hero>
        <MainList>
          <MapField
            source="pair:hasLocation"
            address={record => record['pair:hasLocation'] && record['pair:hasLocation']['pair:label']}
            latitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['pair:latitude']}
            longitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['pair:longitude']}
          />
        </MainList>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SideList>
          {/*<GroupedReferenceHandler*/}
          {/*  source="pair:actorOfMembership"*/}
          {/*  groupReference="MembershipRole"*/}
          {/*  groupLabel="pair:label"*/}
          {/*  filterProperty="pair:membershipRole"*/}
          {/*>*/}
          {/*  <ConditionalSourceDefinedHandler>*/}
          {/*    <RightLabel mb={0} />*/}
          {/*    <ArrayField source="pair:actorOfMembership" >*/}
          {/*      <Box mb={4}>*/}
          {/*        <GridList xs={6} linkType="show" externalLinks>*/}
          {/*          <ReferenceField reference="Organization" source="pair:membershipOrganization" link="show" basePath="/Organization">*/}
          {/*            <AvatarWithLabelField label="pair:label" image="image"/>*/}
          {/*          </ReferenceField>*/}
          {/*        </GridList>*/}
          {/*      </Box>*/}
          {/*    </ArrayField>*/}
          {/*  </ConditionalSourceDefinedHandler>*/}
          {/*</GroupedReferenceHandler>*/}
          <QuickAppendReferenceArrayField reference="Activity" source="pair:involvedIn">
            <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Theme" source="pair:hasTopic">
            <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Skill" source="pair:offers">
            <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
        </SideList>
      </Grid>
    </Grid>
  </Show>
);

export default PersonShow;
